import { useEffect } from 'react'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import type { UIState } from './types'

export const useUIState = create<UIState>()(
  subscribeWithSelector((set) => {
    return {
      loaded: false,
      focused: true, 
      outlinePath: null,
      darkMode: true,
      focusMode: false,

      setLoaded: (loaded: boolean) => set({ loaded }),
      setFocused: (focused: boolean) => set({ focused }),
      setDarkMode: (darkMode: boolean) => set({ darkMode }),
      toggleDarkMode: () => set(({ darkMode }) => ({ darkMode: !darkMode })),
      setFocusMode: (focusMode: boolean) => set({ focusMode }),
      toggleFocusMode: () => set(({ focusMode }) => ({ focusMode: !focusMode })),
    }
  }),
)

export function useUIStateListener<U>(selector: (state: UIState) => U, callback: (state: U) => void) {
  useEffect(() => {
    const unsub = useUIState.subscribe(selector, callback)
    callback(selector(useUIState.getState()))
    return () => {
      unsub()
    }
  }, [selector, callback])
}
