import { Vector3Tuple } from 'three'

const DEFAULT_CAMERA_POSITION = [0, 0, -200] as [x: number, y: number, z: number]
export const cameraConfig = {
  position: DEFAULT_CAMERA_POSITION,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  rotation: [0, Math.PI, -Math.PI] as Vector3Tuple
}
