'use client'

import { FC, useCallback, useEffect, useRef, useState } from "react"

import { Button } from "../Button"

const DEFAULT_EMAIL = "samkilgus@gmail.com"
const clickToCopy = "copy email"
const copied = "copied ✓"

interface EmailLinkProps {
  email?: string
}

export const EmailLink: FC<EmailLinkProps> = ({ email = DEFAULT_EMAIL }) => {
  const [isCopied, setIsCopied] = useState(false)
  const timeout = useRef(0)
  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(email)
    setIsCopied(true)
    window.clearTimeout(timeout.current)
    timeout.current = window.setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }, [email])

  useEffect(() => {
    return () => window.clearTimeout(timeout.current)
  }, [])

  return (
    <>
      {/* plaintext email fallback for no-js browser */}
      <span data-remove-with-js className="italic">samk<span className="hidden">bots</span>ilg<span className="hidden">bots</span>us@gmai<span className="hidden">bots</span>l.c<span className="hidden">bots!</span>om</span>

      <Button
        data-reveal-with-js
        onClick={isCopied ? undefined : handleClick}
        aria-label="copy email to clipboard"
        className="relative"
      >
        {clickToCopy}
        {isCopied && (
          <span aria-hidden className="bg-foreground w-full h-full absolute-center p-0 flex items-center justify-center pointer-events-none">
            {copied}
          </span>
        )}
      </Button>
    </>
  )
}
