import(/* webpackMode: "eager" */ "/vercel/path0/app/styles/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Cursor/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/EmailLink/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/FocusModeToggle/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/FocusProvider/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Frame/svg.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Nav/title.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ParticleCanvasGL/Canvas.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ThemeToggle/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ThemeToggle/ThemeProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ViewportProvider/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Sentient-Variable.woff2\",\"style\":\"normal\"},{\"path\":\"./fonts/Sentient-VariableItalic.woff2\",\"style\":\"italic\"}],\"variable\":\"--font-sentient\",\"display\":\"swap\"}],\"variableName\":\"sentientFont\"}")