'use client'

import { Controller, config } from "@react-spring/core"
import React, { MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"

import { useUIStateListener } from "@/lib/context/ui/context"
import useEventListener from "@/lib/hooks/useEventListener"
import { useMatchMediaCallback } from "@/lib/hooks/useMatchMedia"
import { roundTo } from "@/lib/utils/math"
import { getAncestorWith, hasAncestorThat, hasAncestorWithTagName } from "@/lib/utils/queryParent"
import { interpolate } from "@/lib/utils/range"

import { CURSOR_SPRING_PRECISION, DEFAULT_MOUSE_SIZE, HOVER_BORDER, TEXT_MOUSE_SIZE } from './constants'
import styles from './cursor.module.css'

const defaultControllerData = {
  mx: 0,
  my: 0,
  hx: 0,
  hy: 0,
  hovered: 0,
  active: 0,
  clicked: 0,
  link: 0,
  ...DEFAULT_MOUSE_SIZE
}
type ControllerData = typeof defaultControllerData

const createController = (onChange: (spring: Controller<ControllerData>, data: ControllerData) => void) => {
  const controller: Controller<ControllerData> = new Controller<ControllerData>({
    ...defaultControllerData,
    onChange: ({ value }) => onChange(controller, value),
    config: {
      ...config.stiff,
      friction: 30,
      tension: 300,
      clamp: true,
      precision: CURSOR_SPRING_PRECISION,
    }
  })
  return controller
}

export default function Cursor() {
  const initialized = useRef(false)
  const lastHoveredRef = useRef<Element | null>(null)
  const dotRef = useRef<HTMLDivElement | null>(null)
  const altDotRef = useRef<HTMLDivElement | null>(null)
  const tooltipRef = useRef<HTMLSpanElement>(null)
  const [tooltip, setTooltip] = useState<string | boolean>(false)
  const [tooltipDirection, setTooltipDirection] = useState<string | boolean>('right')
  const [renderTooltip, setRenderTooltip] = useState<boolean>(false)
  const tooltipUpdateTimeout = useRef(-1)
  const handleHoveredElementTimeout = useRef(-1)
  const mutationObserver = useRef<MutationObserver | null>(null)

  const isTouchscreenRef = useRef(false)
  useMatchMediaCallback('(hover: none)', (touch) => { isTouchscreenRef.current = touch })
  
  const internalSpring = useRef(
    createController((controller, { hx, hy, width, height, active, clicked, hovered, link }) => {
      if (dotRef.current) {
        const mx = controller.springs.mx.goal
        const my = controller.springs.my.goal
        const x = interpolate(hovered, mx, hx)
        const y = interpolate(hovered, my, hy)
        const scale = Math.max(0, active + (link * (1 - hovered)) - (clicked * (1 - hovered)) * 0.2)
        dotRef.current.style.transform = `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) scale(${roundTo(scale, 2)})`
        dotRef.current.style.width = `${width}px`
        dotRef.current.style.height = `${height}px`

        if (altDotRef.current && hovered > 0) {
          altDotRef.current.style.transform = `translate(calc(-50% + ${mx}px), calc(-50% + ${my}px)) scale(${roundTo(hovered, 2)})`
        }
      }
    })
  )

  useUIStateListener(({ focused }) => focused, (focused) => {
    if (internalSpring.current && initialized.current) {
      internalSpring.current.start({ active: focused ? 1 : 0 })
    }
  })

  const detectTooltip = (element: Element) => {
    const newTooltip = hasAncestorThat(element, (element) => element.getAttribute('data-tooltip'))
    if (newTooltip) {
      setTooltip(newTooltip)
      setTooltipDirection(element.getAttribute('data-tooltip-direction') ?? 'right')
      clearTimeout(tooltipUpdateTimeout.current)
      tooltipUpdateTimeout.current = window.setTimeout(() => setRenderTooltip(true), 10)
      mutationObserver.current?.observe(element, {
        attributes: true //configure it to listen to attribute changes
      })
    }
    else {
      setRenderTooltip(false)
      clearTimeout(tooltipUpdateTimeout.current)
      tooltipUpdateTimeout.current = window.setTimeout(() => setTooltip(false), 300)
      mutationObserver.current?.disconnect()
    }
  }

  const detectHoverFill = (element: Element) => {
    const elementToFill = getAncestorWith(element, (element) => element.getAttribute('data-mouse-fill'))
    if (elementToFill) {
      const dataValue = elementToFill.getAttribute('data-mouse-fill')
      let hoverBorder = dataValue && parseInt(dataValue)
      if (!hoverBorder || isNaN(hoverBorder)) hoverBorder = HOVER_BORDER

      const { width: _width, height: _height, left, top } = elementToFill.getBoundingClientRect()
      const rWidth = roundTo(Math.round(_width), 1)
      const rHeight = roundTo(Math.round(_height), 1)
      const width = roundTo(rWidth - hoverBorder * 2, 1)
      const height = roundTo(rHeight - hoverBorder * 2, 1)
      const hx = roundTo(left + rWidth / 2, 1)
      const hy = roundTo(top + rHeight / 2, 1)

      const mx = internalSpring.current.springs.mx.goal
      const my = internalSpring.current.springs.my.goal

      const wasHovering = internalSpring.current.springs.hovered.goal === 1
      if (!wasHovering) internalSpring.current.set({ hx: mx, hy: my })
      internalSpring.current.start({ hovered: 1, width, height, hx, hy })
    }
    else {
      internalSpring.current.start({ hovered: 0 })
    } 
  }

  const detectText = (element: Element) => {
    const isSpan = hasAncestorWithTagName(element, 'P')
    const isAnchor = hasAncestorWithTagName(element, 'A')
    const isPlainButton = hasAncestorThat(element, (element) => element.hasAttribute('data-plain-button'))
    internalSpring.current.start({ ...(isSpan && !isAnchor ? TEXT_MOUSE_SIZE : DEFAULT_MOUSE_SIZE), link: (isAnchor || isPlainButton) ? 1 : 0 })
  }

  const handleNewHoveredElement = useCallback(() => {
    const mx = internalSpring.current.springs.mx.goal
    const my = internalSpring.current.springs.my.goal
    const hoveredElement = document.elementFromPoint(mx, my)
    if (hoveredElement) {
      if (lastHoveredRef.current !== hoveredElement) {
        detectText(hoveredElement)
        detectTooltip(hoveredElement)
        detectHoverFill(hoveredElement)
        lastHoveredRef.current = hoveredElement
      }
    }
  }, [])

  const handleMouseMove = (ev?: Event) => {
    if (!ev || isTouchscreenRef.current) return
    initialized.current = true
    const event = ev as unknown as MouseEvent
    internalSpring.current.set({ mx: event.clientX, my: event.clientY })
    internalSpring.current.start({ active: isTouchscreenRef.current ? 0 : 1 })

    window.clearTimeout(handleHoveredElementTimeout.current)
    handleHoveredElementTimeout.current = window.setTimeout(handleNewHoveredElement, 10)
  }
  useEventListener('mousemove', handleMouseMove)

  const handleMouseDown = () => {
    if (!isTouchscreenRef.current) internalSpring.current.start({ clicked: 1 })
  }
  useEventListener('mousedown', handleMouseDown)

  const handleMouseUp = () => {
    if (!isTouchscreenRef.current) internalSpring.current.start({ clicked: 0 })
  }
  useEventListener('mouseup', handleMouseUp)

  const handleMouseOut = (ev?: Event) => {
    if (ev && !(ev as unknown as MouseEvent).relatedTarget) {
      internalSpring.current.start({ active: 0 })
    }
  }
  useEventListener('pointerout', handleMouseOut)

  const handleScroll = () => {
    if (internalSpring.current.springs.clicked.goal === 1 && !isTouchscreenRef.current) internalSpring.current.start({ active: 0 })
  }
  useEventListener('scroll', handleScroll)

  // to handle tooltip changes without needing the mouse to re-enter the tooltiped element
  useEffect(() => {
    if (!mutationObserver.current) mutationObserver.current = new MutationObserver(() => {
      lastHoveredRef.current = null
      handleNewHoveredElement()
    })
    return () => {
      mutationObserver.current?.disconnect()
    }
  }, [handleNewHoveredElement])

  useLayoutEffect(() => {
    // cursor is set to 'default' unless javascript is enabled.
    document.documentElement.style.setProperty("--cursor", "none")
  }, [])

  return (
    <>
      <div aria-hidden ref={dotRef} className={styles.dot}>
        {tooltip && (
          <span
            aria-hidden
            ref={tooltipRef}
            className={`
              whitespace-nowrap absolute top-1/2 -translate-y-1/2
              text-[white] italic text-sm
              ${tooltipDirection === 'right' ? 'left-[calc(100%+8px)]' : 'right-[calc(100%+8px)]'}

              ${renderTooltip ? 'opacity-100 blur-0' : 'opacity-0 blur-sm'}
              transition-[opacity,filter] duration-300 ease-out
            `}
          >
            {tooltip}
          </span>
        )}
      </div>
      <div aria-hidden ref={altDotRef} className={styles.altDot} />
    </>
  )
}
