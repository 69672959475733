'use client'

import { useUIState, useUIStateListener } from "@/lib/context/ui/context"
import { useMatchMediaCallback } from "@/lib/hooks/useMatchMedia"

import { THEME } from "./constants"

export default function DarkModeProvider() {
  useMatchMediaCallback('(prefers-color-scheme: dark)', useUIState.getState().setDarkMode)

  useUIStateListener((state) => state.darkMode, (darkMode) => {
    const html = document.querySelector('html')
    if (html) html.setAttribute('data-theme', darkMode ? THEME.DARK : THEME.LIGHT)
  })

  return <></>
}
