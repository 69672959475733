import { useEffect, useRef } from 'react'

interface Options {
  target: () => HTMLElement | Document
  initial: boolean
  animationFrame: boolean
}

export type EventCallback = (event?: Event) => void

/**
 * Hook for creating event listeners.
 * @param {string} eventType - type of event to listen for. if no type provided, no event listener will be spawned
 * @param {function} callback - event handler callback method
 * @param {Object} [options] - options object
 * @param {Object} [options.target=window] - the event target element. If null,
 *   window will be used as the target.
 * @param {boolean} [options.initial=false] - If true, the callback method will
 *   be called once on initialization. Note: callback should handle not having an
 *   event object passed to it.
 */
export default function useEventListener(
  eventType?: string,
  callback?: EventCallback,
  options?: Partial<Options>,
) {
  const { target, initial = false, animationFrame = true } = options ?? {}
  const callbackRef = useRef<EventCallback | undefined>()
  const afRef = useRef(-1)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (eventType) {
      const targetElement = target ? target() : window

      const executeCallback = (event?: Event) => {
        if (callbackRef.current) callbackRef.current(event)
      }

      const eventListener = (event?: Event) => {
        if (animationFrame) afRef.current = window.requestAnimationFrame(() => executeCallback(event))
        else executeCallback(event)
      }

      if (initial) eventListener()
      // Attach event to target
      targetElement.addEventListener(eventType, eventListener)
      // Remove event on cleanup
      return () => targetElement.removeEventListener(eventType, eventListener)
    }
  }, [animationFrame, eventType, initial, target])
}
