'use client'

import { useReducedMotion } from '@react-spring/core'
import { Stats } from '@react-three/drei'
import { Canvas as ThreeCanvas } from '@react-three/fiber'
import dynamic from 'next/dynamic'
import { FC } from 'react'

import useUserAgent from '@/lib/hooks/useUserAgent'

import { cameraConfig } from './constants'
const Particles = dynamic(() => import("@/components/ParticleCanvasGL"), { ssr: false }) as FC

const isDev = process.env.NODE_ENV === "development"

export default function Canvas(): JSX.Element | null {
  const isSafari = useUserAgent((ua) => ua.isSafari)
  
  // the canvas should only render if default preference enables motion
  // this doesn't subscribe to the global state property on purpose
  const reducedMotion = useReducedMotion()

  return !isSafari && !reducedMotion ? (
    <ThreeCanvas
      camera={cameraConfig}
      gl={{
        powerPreference: 'high-performance',
        antialias: false,
        stencil: false,
        depth: false,
        alpha: false,
      }}
      style={{ pointerEvents: 'none', position: 'fixed' }}
      orthographic
      frameloop='demand'
      className="
        touch-none z-[3] mix-blend-difference opacity-100 dark:opacity-70 transition-opacity duration-1000 ease-[ease]
        fixed top-0 left-0 w-screen h-full pointer-events-none
      "
      flat
    >
      <Particles />
      {isDev && (
        // <Perf position='top-left' />
        <Stats />
      )}
    </ThreeCanvas>
  ) : null
}
