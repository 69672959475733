'use client'

import { useReducedMotion } from "@react-spring/core"

import { useUIState } from "@/lib/context/ui/context"
import useUserAgent from "@/lib/hooks/useUserAgent"

import styles from './focusModeToggle.module.css'

export function FocusToggle() {
  const isSafari = useUserAgent((ua) => ua.isSafari)
  const reducedMotion = useReducedMotion()
  const toggleFocusMode = useUIState((state) => state.toggleFocusMode)
  const focusMode = useUIState((state) => state.focusMode)

  return (
    <button
      data-reveal-with-js
      data-mouse-fill="1"
      data-focus-mode={focusMode}
      onClick={toggleFocusMode}
      aria-label="toggle theme"
      data-tooltip={focusMode ? 'focused' : 'unfocused'}
      data-tooltip-direction='left'
      aria-hidden={!!reducedMotion}
      className={`${styles.toggleButton} fix-blend-mode-safari outline-none ${reducedMotion || isSafari ? 'opacity-0 pointer-events-none' : ''}`}
    />
  )
}
