'use client'

import type { LinkField } from "@prismicio/client"
import { PrismicNextLink } from "@prismicio/next"
import { ButtonHTMLAttributes, ElementType, forwardRef } from "react"

type ElementTypeOption = HTMLButtonElement | HTMLAnchorElement

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: ElementType
  field?: LinkField
  target?: '_blank'
  href?: string
}

export const Button = forwardRef<ElementTypeOption, ButtonProps>(({ as: asElement = 'button', className, field, ...rest }, ref) => {
  let Wrapper = asElement
  if (field && asElement === 'a') Wrapper = PrismicNextLink

  return (
    <Wrapper
      ref={ref}
      field={field}
      data-mouse-fill
      className={`
        inline-flex items-center justify-center rounded-full px-[1rem] text-[0.95rem] wide:text-[1rem]
        min-h-[var(--nav-button-height)] leading-none text-background bg-foreground
        hover:bg-transparent hover:text-foreground transition-colors duration-200 ease-ease
        select-none overflow-hidden italic whitespace-nowrap
        ${className}
      `}
      {...rest}
    />
  )
})

Button.displayName = 'Button'
