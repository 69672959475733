'use client'

import { useEffect } from "react"

import { useUIState } from "@/lib/context/ui/context"

export default function FocusProvider() {
  useEffect(() => {
    const { setFocused } = useUIState.getState()
    const onVisibilityChange = () => setFocused(document.hidden)
    const onBlur = () => setFocused(false)
    const onFocus = () => setFocused(true)
    window.addEventListener('visibilitychange', onVisibilityChange)
    window.addEventListener('blur', onBlur)
    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
      window.removeEventListener('blur', onBlur)
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  return <></>
}
