import { useEffect, useState } from 'react'

export function useMatchMedia(mediaQuery: string) {
  const [matches, setMatches] = useState<boolean | null>(null)

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches)
    const mediaQueryList = window.matchMedia(mediaQuery)

    setMatches(mediaQueryList.matches)
    mediaQueryList.addEventListener('change', onChange)
    return () => {
      mediaQueryList.removeEventListener('change', onChange)
    }

  }, [mediaQuery])

  return matches
}

export function useMatchMediaCallback(mediaQuery: string, callback: (value: boolean) => void) {
  useEffect(() => {
    const onChange = (event: MediaQueryListEvent) => callback(event.matches)
    const mediaQueryList = window.matchMedia(mediaQuery)

    callback(mediaQueryList.matches)
    mediaQueryList.addEventListener('change', onChange)
    return () => {
      mediaQueryList.removeEventListener('change', onChange)
    }

  }, [callback, mediaQuery])
}
