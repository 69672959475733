import { Vector2 } from "three"

export const PI2 = Math.PI * 2

export function modulo(n: number, m: number) {
  return ((n % m) + m) % m
}

export function roundTo(value: number, precision: number) {
  const x = 10 ** precision
  return Math.round(value * x) / x
}

export function wrap(val: number, min: number, max: number) {
  return modulo(val - min, max - min) + min
}

export function clamp(val: number, min: number, max: number) {
  return Math.min(Math.max(val, min), max)
}

export function angleDiff(currentAngle: number, targetAngle: number) {
  let diff = targetAngle - currentAngle
  if (diff > Math.PI) diff = (diff - PI2)
  else if (diff < -Math.PI) diff = PI2 + diff
  return diff
}

export function nearestPowerOf2(n: number) {
  return 1 << 31 - Math.clz32(n)
}

export function roundToNearestDivisible(n: number, d: number) {
  return Math.round(n / d) * d
}

export function reflectOver(n: number, axis: number) {
  return 2*axis - n
}

export function unitApprox(vec: Vector2) {
  if (vec.x === 0 && vec.y === 0) vec.set(0, 0)
  else {
    const ax = Math.abs(vec.x)
    const ay = Math.abs(vec.y)
    let ratio = 1 / Math.max(ax, ay)
    ratio = ratio * (1.29289 - (ax + ay) * ratio * 0.29289)
    vec.multiplyScalar(ratio)
  }
  return vec
}

export function inRangeInc(value: number, min: number, max: number) {
  return value >= min && value <= max
}

export function inRangeExc(value: number, min: number, max: number) {
  return value > min && value < max
}

export function inRange(value: number, min: number, max: number, inclusive = true) {
  return inclusive ? inRangeInc(value, min, max) : inRangeExc(value, min, max)
}
