import { UserAgent, parse } from 'next-useragent'
import { useEffect, useState } from 'react'

export default function useUserAgent<T>(selector: (ua: UserAgent) => T) {
  const [value, setValue] = useState<T>()
  useEffect(() => {
    setValue(selector(parse(navigator.userAgent)))
  }, [selector])
  return value
}
