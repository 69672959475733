export const DEFAULT_MOUSE_SIZE = {
  width: 12,
  height: 12,
}

export const TEXT_MOUSE_SIZE = {
  width: 1.5,
  height: 20,
}

export const HOVER_BORDER = 1.25

export const CURSOR_SPRING_PRECISION = 0.001
