'use client'

import { useUIState } from "@/lib/context/ui/context"

import styles from './themeToggle.module.css'

export function ThemeToggle() {
  const toggleDarkMode = useUIState((state) => state.toggleDarkMode)
  const darkMode = useUIState((state) => state.darkMode)

  return (
    <button
      data-reveal-with-js
      data-mouse-fill="1"
      onClick={toggleDarkMode}
      aria-label="toggle theme"
      data-tooltip={darkMode ? 'dark' : 'light'}
      className={`${styles.toggleButton} fix-blend-mode-safari outline-none`}
    />
  )
}
